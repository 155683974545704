/* Set Bootstrap Grid Breakpoints. */
$grid-breakpoints: (
  xs: 0,
  sm: 540px,
  md: 700px,
  lg: 1024px,
  xl: 1440px
);

/* Bootstrap Import */ 
@import "bootstrap/bootstrap";

/* Global Variables */

// Fonts 
@import "bootstrap/custom_partials/fonts";
$font-family: 'Greycliff', sans-serif; 
$font-family-bold: 'GreycliffBold', sans-serif; 
$font-family-semibold: 'GreycliffDemiBold', sans-serif;
$font-family-extra-bold: 'GreycliffExtraBold', sans-serif; 
$font-family-light-italic: 'GreycliffLightItalic', sans-serif; 

// Theme Color Scheme 

$primary:               #002046;
$primary-2:             #eeeeee; 
$secondary:             #f1df73;
$accent:                #14365f;
$light:                 #FFFFFF;
$danger:                #ed3f54;
$mod-gradient-start:    #B4CECB;
$mod-gradient-end:      #2C5A69;      
$mod-footer:            #7FA0A5;
$help-nav:              #B4CECB;
// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
   "primary":    $primary,
   "primary-2": $primary-2,
   "secondary":  $secondary,
   "accent": $accent,
   "light": $light,
   "danger": $danger,
   "mod-gradient-start": $mod-gradient-start,
   "mod-gradient-end": $mod-gradient-end,
   "mod-footer": $mod-footer,
   "help-nav": $help-nav
   );

$theme-colors: (
    "primary":    $primary,
    "primary-2": $primary-2,
    "secondary":  $secondary,
    "accent": $accent,
    "light": $light,
    "danger": $danger,
    "mod-gradient-start": $mod-gradient-start,
    "mod-gradient-end": $mod-gradient-end,
    "mod-footer": $mod-footer,
    "help-nav": $help-nav
);

// background classes

.bg-primary{
    background-color: $primary !important; 
}

.bg-primary-2{
    background-color: $primary-2 !important; 
}

.bg-secondary{
    background-color: $secondary !important; 
}

.bg-accent{
    background-color: $accent !important;
}

.bg-light{
    background-color: $light !important; 
}

.bg-gradient-start {
    background-color: $mod-gradient-start !important;
}

.bg-gradient {
    background-image: linear-gradient($mod-gradient-start, $mod-gradient-end);
}

.bg-mod-footer {
    background-color: $mod-footer !important;
}

.bg-help-nav {
    background-color: $help-nav !important;
}

// text-color classes

.text-primary{
    color: $primary !important; 
}

.text-primary-2{
    color: $primary-2 !important; 
}

.text-secondary{
    color: $secondary !important; 
}

.text-accent{
    color: $accent !important; 
}

.text-light{
    color: $light !important; 
}

/* Custom Partial Imports */

@import "bootstrap/custom_partials/main";
@import "bootstrap/custom_partials/footer";
@import "bootstrap/custom_partials/nav";

/* Base Styles */

html, body {
    color: #000;
    background-color: #FFF;
    max-width: 100%; 
    overflow-x: hidden;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; 

}

.container {
    max-width: 100%; 
    padding: 0px; 
}

.row {
    margin-left: 0px !important; 
    margin-right: 0px !important; 
}

h1 {
   font-family: $font-family-bold;  
   font-size: 68px; 
   @media(max-width:1024px){
       
   }
   @media(max-width:700px){

   }
}

h2 {
    font-family: $font-family;  
}

h3 {
    font-family: $font-family;  
}

p {
    font-family: $font-family;  
    font-size: 18px; 
    line-height: 28px; 
    color: black; 
}

a {
    font-family: $font-family;  
    cursor: pointer;
    text-decoration: none; 
    &:hover{
        text-decoration: none !important; 
        color: $secondary !important; 
    }
}



// Universal Styles Specific to Your Theme Go Here

.text-small {
    font-size: 16px;
    font-style: italic; 
    line-height: 18px; 
}

button {
    &:focus {
        outline: none !important; 
    }
}



