@font-face {
    font-family: Greycliff; 
    src: url("/assets/fonts/GreycliffCF-Regular.otf") format("opentype");
}

@font-face {
    font-family: GreycliffMedium; 
    src: url("/assets/fonts/Greycliff-Medium"); 
    font-style: normal; 
}

@font-face {
    font-family: GreycliffBold; 
    font-style: normal; 
    src: url("/assets/fonts/GreycliffCF-Bold.otf") format("opentype"); 
}

@font-face {
    font-family: GreycliffDemiBold; 
    font-style: normal; 
    src: url("/assets/fonts/GreycliffCF-DemiBold.otf") format("opentype"); 
}

@font-face {
    font-family: GreycliffExtraBold; 
    font-style: normal; 
    src: url("/assets/fonts/GreycliffCF-ExtraBold.otf") format("opentype"); 
}

@font-face {
    font-family: GreycliffLight; 
    font-style: normal; 
    src: url("/assets/fonts/GreycliffCF-Light.otf") format("opentype"); 
}

@font-face {
    font-family: GreycliffLightItalic; 
    font-style: normal; 
    src: url("/assets/fonts/GreycliffCF-LightOblique") format("opentype"); 
}

@font-face {
    font-family: GreycliffItalic; 
    font-style: normal; 
    src: url("/assets/fonts/GreycliffCF-MediumOblique.otf") format("opentype"); 
}