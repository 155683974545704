.footer-logo {
    max-width: 205px;
    margin-bottom: 15px; 
}

.footer-link {
    font-weight:500;
    color: $light !important; 
    &:hover, &:active {
        color: $primary !important; 
    }
}
.footer-contact {
    color: $light;
    &:link, &:visited, &:active {
        color: $light !important;
    }
    &:hover {
        color: $primary !important;
    }
}
