
.headline {
    color: $primary !important; 
    font-family: $font-family-extra-bold; 
    line-height: 72px;
    @media(max-width:850px){
        font-size: 52px; 
        line-height: 58px; 
    }
    @media(max-width:540px){
        font-size: 29px; 
        line-height: 36px; 
    }
    @media(max-width:371px){
        font-size: 24px; 
        line-height: 30px; 
    }
}
.sub-heading {
    color: $primary !important; 
    letter-spacing: 1px;
    @media(max-width:540px){
        font-size: 20px; 
    }
    @media(max-width:370px){
        font-size: 18px; 
    }
}

//Video Section

.fixed-content {
    max-width: 966px;
}

iframe {
    width: 956px;
    height: 538px;
    @media(max-width:700px){
        height: 350px; 
        max-width: 100%;
    }
    @media(max-width:540px){
        height: 220px; 
        max-width: 100%;
    }
}

.video-backdrop {
    background-color: white;
    height:450px; 
    position: absolute; 
    @media(max-width:700px){
        height: auto; 
    }
}

.video-embed {
    background-color: white;
    border-radius: 2%; 
    border: 5px solid $primary-2; 
    overflow: hidden;
    box-shadow: 2px 5px 17px -1px rgba(79,79,79,0.73)
}

//main content


.content {
    @media(max-width:600px){
        padding-left: 0px !important; 
        padding-right: 0px !important; 
    }
    .share-text {
        font-weight: 400; 
        @media(max-width:540px){
            font-size: 20px; 
        }
        @media(max-width:370px){
            font-size: 18px; 
        }
    }
    .get-help-link {
        width: 154px; 
        height: 44px; 
        line-height: 44px; 
        font-size: 24px; 
        font-family: $font-family-semibold;
        &:hover, &:active, &:focus {
            background-color: $primary !important; 
            color: $light !important; 
        }
        @media(max-width:540px){
            width: 114px; 
            font-size: 20px; 
            margin: 0px !important;  
        }
        @media(max-width:540px){
             width: 114px; 
        }
        
    }
    .content-headline {
        font-weight: 900; 
        font-size: 44px; 
        line-height: 52px; 
        letter-spacing: 0.6px;
        @media(max-width:540px){
            font-size: 29px; 
            letter-spacing: 0px; 
            line-height: 32px; 
        }
        @media(max-width:370px){
            font-size: 24px; 
            line-height:26px;
        }
    }
    .content-sub-heading {
        font-weight: 400; 
        font-size: 28px; 
        color: #8b929c;
        @media(max-width:540px){
            font-size: 20px; 
        }
        @media(max-width:370px){
            font-size: 18px; 
            line-height:26px;
        }
    }
    .base-image {
        max-height: 180px; 
        margin-top: -30px; 
        @media(max-width:1024px){
            align-self: center; 
            margin-left: auto; 
            margin-right: auto;
        }
        @media(max-width:767px){
            margin-top: 0px; 
            max-height: 250px; 
        }
    }
}

// Accordion

.card-header {
    border: 0px;

    h3 {
        font-size: 24px; 
        line-height: 24px; 
        font-family: $font-family-semibold;
        @media(max-width:540px){
            font-size: 20px; 
            line-height: 20px; 
        }
    }
    a {
        font-family: $font-family-semibold; 
        &:hover {
            color: $light !important; 

        }
        &:focus {
            color: $light !important; 
        }
    }
    &:hover {
        transition: color .2s ease-in-out;
        background-color: #2a6496 !important; 

    }
    &:focus {
        transition: color .2s ease-in-out;
        background-color: #2a6496 !important; 
    }
}

.card-body {
    border: 0px; 
    img {
        max-width: 100%;
    }
    h4 {
        font-size: 22px; 
        @media(max-width:540px){
            font-size: 20px; 
            line-height: 22px; 
        }
    }
    p {
        font-size: 16px; 
    }
    a {
        color: #1ba9cb; 
        &:hover {
            color: black !important; 
        }
    }
    ol, li {
        font-size: 12px; 
        
    }
    ::marker {
        font-size: 16px;     
    }
}

.stages {
    img {
        @media (max-width:1024px){
            max-width: 150px;
            max-height: 150px;
        }
    }
}

.animated-accordion {
    font-size: 24px; 
    @media(max-width:540px){
        font-size: 20px; 
    }
}
.animated-accordion-hide {
    display: none !important; 
}

// Medical Review Bar 
.medical-review {
    line-height: 24px !important; 
}
.medical-review-icon {
    height: 52px;
}

// Get Help 

.help-headline {
    font-size: 66px;
    @media(max-width:1023px){
        font-size: 48px; 
    }
    @media(max-width:767px){
        font-size: 36px; 
    }
}

.accordion-help {
    h2 {
        font-family: $font-family-semibold; 
        font-size: 24px;
        line-height: 24px; 
        @media(max-width:540px){
            font-size: 20px; 
            line-height: 20px;
        }
        
    }
    h3 {
        font-family: $font-family-bold; 
        font-size: 18px; 
    }
    a {
        font-family: $font-family-bold;
        font-size: 16px; 
    }
    .help-links {
        font-family: $font-family;
        font-size: 16px; 
    }
}