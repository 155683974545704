.active {
    .nav-link {
        color: $primary; 
        text-decoration: underline;
    }
}
.nav-link {
    color: $primary; 
    font-family: $font-family-bold; 
    font-size: 20px; 
    &:hover {
      color: $primary !important;
      text-decoration: underline !important;
    }
}

.nav-caret {
  color: $primary-2; 
  font-size: 50px !important; 
  top: 27px; 
  left: 50%; 
  margin-left:-14px;
  @media (max-width:1023px){
      display: none !important; 
  }
}

.nav-item {
  @media(max-width:1023px){
      background-color: $help-nav;
      font-size: 18px; 
      border-bottom: 1px solid rgba(255,255,255,0.1); 
  }
}

//Animated Icon 
  /* Icon */

  .animated-icon {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  .animated-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .animated-icon span {
    background: #fff;
    @media (max-width:701px) {
      background: $primary;
    }
  }
  
  .animated-icon span:nth-child(1) {
    top: 0px;
  }
  
  .animated-icon span:nth-child(2), .animated-icon span:nth-child(3) {
    top: 10px;
  }
  
  .animated-icon span:nth-child(4) {
    top: 20px;
  }
  
  .animated-icon.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
  }
  
  .animated-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .animated-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .animated-icon.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
  }

